<template>
  <div id="game-credit" class="container-fluid">
    <div class="page-header mb-1">
      <strong>รายงานเครดิตเกม</strong>
    </div>

    <GameCreditBox :accountId="accountId" />
  </div>
</template>
<script>
import GameCreditBox from './components/GameCreditBox'

export default {
  name: 'GameCredit',
  components: {
    GameCreditBox
  },
  data() {
    return {
      accountId: this.$store.state.account._id
    }
  }
}
</script>
